import React from "react";
import { FormattedMessage } from "react-intl";
//import PropTypes from 'prop-types'

import {
  FacebookShareCount,
  PinterestShareCount,
  TumblrShareCount,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailShareButton,
  TumblrShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  TumblrIcon,
  EmailIcon,
} from "react-share";

class ShareOn extends React.Component {
  render() {
    const props = this.props;
    const shareUrl = props.shareUrl;
    const url = "https://www.walterperdan.com" + shareUrl;
    const title = props.title;
    const image = props.image;

    return (
      <div className="section">
        <div className="box has-padding-top-5">
          <h6 className="is-size-6-desktop is-size-6-touch">
            <FormattedMessage id="sharing" />
          </h6>
          <nav className="level">
            <div className="level-item has-cursor-pointer">
              <FacebookShareButton url={url} quote={title} className="icon">
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <FacebookShareCount url={url} className="icon">
                {(count) => count}
              </FacebookShareCount>
            </div>

            <div className="level-item has-cursor-pointer">
              <TwitterShareButton url={url} title={title} className="icon">
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>
            <div className="level-item has-cursor-pointer">
              <LinkedinShareButton
                url={url}
                windowWidth={750}
                windowHeight={600}
                className="icon"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>

            <div className="level-item has-cursor-pointer">
              <PinterestShareButton
                url={url}
                media={image}
                windowWidth={1000}
                windowHeight={730}
                className="icon"
              >
                <PinterestIcon size={32} round />
              </PinterestShareButton>

              <PinterestShareCount url={url} className="icon" />
            </div>
            <div className="level-item has-cursor-pointer">
              <TumblrShareButton
                url={url}
                title={title}
                windowWidth={660}
                windowHeight={460}
                className="icon"
              >
                <TumblrIcon size={32} round />
              </TumblrShareButton>

              <TumblrShareCount url={url} className="icon" />
            </div>
            <div className="level-item has-cursor-pointer">
              <EmailShareButton
                url={url}
                subject={title}
                body="body"
                className="icon"
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}
export default ShareOn;
